import "../App.css";
import { memo } from "react";
import waterqm_logo from "../assets/images/waterqm_logo.png";
import Layout from "react-masonry-list";

// featured images
import waterqm_prototype from "../assets/images/waterqm_prototype.png";
import waterqm_render from "../assets/images/waterqm_render.png";
import waterqm_prototype_2 from "../assets/images/waterqm_prototype (2).png";
import waterqm_field from "../assets/images/waterqm_field.png";
import waterqm_prototype_3 from "../assets/images/waterqm_prototype (3).png";
import waterqm_graph from "../assets/images/waterqm_graph.png";

// If item contains img elements, don't forget set img's width. In order to get a better display effect, you can also set img's `object-fit` to `contain`.

// Memoized LazyLoadImage Component
const LazyLoadImage = memo(({ src, alt, width }) => {
    return <img src={src} alt={alt} style={{ width: "100%", height: "auto", objectFit: "cover" }} height="auto" />;
});
const VentureStudio = () => {
    return (
        <>
            <section>
                <div className="content">
                    <h1>
                        The <span className="hardstuff-no-colour">Hard Stuff</span> Venture Studio
                    </h1>
                    <div className="content">
                        Our venture studio builds eco-tech and sustainability hardware startups solving the really tough
                        challenges.
                        <section>
                            <img src={waterqm_logo} width="200" alt="WaterQM logo" />
                            <p>
                                Our latest venture, <strong>WaterQM</strong> is centred all around improving the
                                monitoring water quality for the UK's 200,000 km of riverways and lakes.
                                <br />
                                <br />
                                Because no one likes swimming in 💩.
                            </p>
                            <Layout
                                colCount={2}
                                minWidth={"60"}
                                items={[
                                    waterqm_prototype,
                                    waterqm_render,
                                    waterqm_prototype_2,
                                    waterqm_field,
                                    waterqm_prototype_3,
                                    waterqm_graph
                                ].map((image, index) => (
                                    <LazyLoadImage key={index} src={image} alt={`WaterQM ${index}`} />
                                ))}
                                gap={15}
                            />
                            <p>Exciting updates coming soon!</p>
                        </section>
                    </div>
                    <div className="content">
                        Want to hear more? Sign up to <a href="https://eepurl.com/iVq7tk">our newsletter</a>, or write
                        to us at <a href="mailto:waterqm@hard-stuff.com">waterqm@hard-stuff.com</a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VentureStudio;
