import vector from "./MaskedImg_assets/vector.svg";

const MaskedImg = (props) => {
    const { src, width, caption, style } = props;

    return (
        <div>
            <img
                src={src}
                // width={width === null ? "100%" : width}
                style={{
                    maxWidth: width == null ? "100%" : width,
                    maskImage: `url(${vector})`,
                    maskSize: "100% 100%", // Ensures the mask scales to both the width and height of the image
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    WebkitMaskImage: `url(${vector})`,
                    WebkitMaskSize: "100% 100%", // Same for WebkitMaskSize
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    ...style
                }}
                alt={caption || ""}
            />
            {caption && (
                <div
                    style={{
                        fontSize: "0.7em",
                        lineHeight: "1.3em",
                        paddingBottom: "5%",
                        margin: "0.7em 5%",
                        borderBottom: "solid 1px white",
                    }}
                >
                    {caption}
                </div>
            )}
        </div>
    );
};

export default MaskedImg;
