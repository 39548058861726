import "../App.css";
import FitInFigure from "../components/FitInFigure";
import Layout from "react-masonry-list";
import MaskedImg from "../components/MaskedImg";

// featured images
import smartshift_prototype from "../assets/images/smartshift_prototype.png";
import waterqm_prototype from "../assets/images/waterqm_prototype.png";
import climate_iot from "../assets/images/climate_iot.png";
import agri_built from "../assets/images/agri_built.png";

// prototype images
import printing from "../assets/images/threeD_printing.png";
import drilling from "../assets/images/drilling.png";
import coding from "../assets/images/coding.png";
import pcb_design from "../assets/images/pcb_design.png";
import soldering from "../assets/images/pico_to_pi_soldering.png";
import waterqm_render from "../assets/images/waterqm_render.png";
import waterqm_prototype_2 from "../assets/images/waterqm_prototype (2).png";
import lab_testing from "../assets/images/lab_testing.jpg";

const featured = [
    <MaskedImg
        src={smartshift_prototype}
        caption={
            <span>
                Automatic price-optimising of home energy usage to reduce bills, reduce emissions, and protect the
                national grid, with <a href="https://www.smartshiftenergy.co.uk/">SmartShift Energy</a>
            </span>
        }
    />,
    <MaskedImg
        src={waterqm_prototype}
        caption={
            <span>
                Low-cost riverway water quality monitoring to improve ecological and public health, with WaterQM. Find
                out more about our <a href="./venture-studio">venture studio</a>
            </span>
        }
    />,
    <MaskedImg
        src={climate_iot}
        caption={
            <span>
                A quick demonstrator for a client to be used to prove Internet-of-Things concepts to their investors
            </span>
        }
    />,
    <MaskedImg
        src={agri_built}
        caption={
            <span>
                Converting cow manure (a <strong>huuuge</strong> methane emitter) into valorisable fertiliser, reducing
                agriculture's environmental impact whilst saving money, with{" "}
                <a href="https://ag-e-gen.com/">Ag E Gen</a>.
            </span>
        }
    />,
];

// If item contains img elements, don't forget set img's width. In order to get a better display effect, you can also set img's `object-fit` to `contain`.

const Startups = () => {
    return (
        <>
            <h1>Our work with startups</h1>
            <div className="content">
                <h3 style={{ textAlign: "left" }}>Featured</h3>
                <Layout
                    minWidth={100}
                    colCount={2}
                    items={featured.map((item) => (
                        <div key={item.id}>{item}</div>
                    ))}
                    gap={50}
                />
                <span>
                    <p>
                        "The prototyping work has helped us understand what we would be able to offer customers. Instead
                        of starting from scratch, we are now more confident in being able to iterate using existing
                        products, while also having a clearer understanding of where further innovation would open up
                        new product opportunities.
                    </p>
                    <p>
                        We really liked the “can do” attitude of Hard Stuff, and their no-nonsense way of structuring
                        the contract and delivery milestones, which went further that we imagined at the start of the
                        project. We feel their work represents very good value for money." -{" "}
                        <a href="https://www.smartshiftenergy.co.uk/">SmartShift Energy</a>
                    </p>
                </span>
            </div>
            <section>
                <div className="content stack_responsive">
                    <div>
                        <h2>
                            <span className="hardstuff">Hard Stuff</span> works with hardware startups fixing hard
                            problems.
                        </h2>
                        <p>
                            We prototype, test, and build ventures alongside a generation of sustainability and
                            impactful hardware startups.
                        </p>
                        <ul style={{ textAlign: "left", marginTop: "0.25em" }}>
                            <strong>We're here for</strong>
                            <li style={{ marginBottom: "0.25em" }}>validating assumptions</li>
                            <li style={{ marginBottom: "0.25em" }}>
                                building proof of concepts for your customers and that streamline raising investment
                            </li>
                            <li style={{ marginBottom: "0.25em" }}>
                                slotting into your existing capabilities to cover the technical areas that you need to
                                go <strong>BIG</strong>
                            </li>
                        </ul>
                    </div>
                    <FitInFigure height="80vh" maxWidth="80%" />
                </div>
            </section>
            {/* <MinimumViableProduct /> */}
            <section>
                <div className="content">
                    <h2>How do we work with you?</h2>
                    <div className="stack_responsive">
                        <Layout
                            minWidth={100}
                            colCount={2}
                            items={[
                                <MaskedImg src={printing} />,
                                <MaskedImg src={drilling} />,
                                <MaskedImg src={soldering} />,
                                <MaskedImg src={lab_testing} />,
                            ].map((item) => (
                                <div key={item.id}>{item}</div>
                            ))}
                            gap={10}
                        />
                        <div>
                            <p>
                                We are your hardware development partner for sustainability and high-impact industries,
                                including agriculture, renewables, environmental monitoring, Internet of Things,
                                heating/cooling, clean manufacturing, and circular economy.
                            </p>
                        </div>
                    </div>
                    <div className="stack_responsive">
                        <div>
                            <p>
                                We operate a services model, keeping sales orders quick and light so we're
                                laser-focussed on only the most critical tasks at hand.
                                <br />
                                This covers prototyping (functional and/or aesthetic), testing, advising, ideating, to
                                even interim-CTOing.
                            </p>
                            <a href="/contact">Get in touch</a>
                        </div>
                        <Layout
                            minWidth={100}
                            colCount={2}
                            items={[
                                <MaskedImg src={waterqm_prototype_2} />,
                                <MaskedImg src={coding} />,
                                <MaskedImg src={waterqm_render} />,
                                <MaskedImg src={pcb_design} />,
                            ].map((item) => (
                                <div key={item.id}>{item}</div>
                            ))}
                            gap={10}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Startups;
