import FlexBox from "../../components/FlexBox";
import ItsTimeToBuild from "../../components/ItsTimeToBuildX";

// import local icons

export const WhatIsHardStuffDoing = (props) => {
    const elements = [
        {
            title: "🌱 Sustainability hardware startups",
            content: (
                <>
                    <p>It's tough changing the World. You don't have to do it alone.</p>
                    <span className="hardstuff-no-colour">Hard Stuff</span> works directly with{" "}
                    <a href="/startups">hardware startups</a>, guiding you from your earliest idea to your Minimum
                    Viable Product through rapid prototyping, in-field testing, engineering expertise, scaling support,
                    and venture building.
                    <p>
                        <strong>We</strong> focus on building and testing.
                        <br />
                        <strong>You</strong> focus on raising and selling.
                        <br />
                        <br />
                    </p>
                    <a href="https://calendar.app.google/LKY4VL67TaSfDuLGA">
                        <button>Book a call</button>
                    </a>
                </>
            ),
        },
        {
            title: "💰 Incubators and investors",
            content: (
                <>
                    <p>Are you investing in the future?</p>
                    <p>
                        <a href="/startups">We work with your portfolio</a> and cohort founders to deliver true,
                        realisable impact, using prototypes and testing to prove impactful solutions.
                    </p>
                    <p>
                        Let's unlock the next generation of hardware innovations that will solve massive global issues.
                        <br />
                        <a href="https://calendar.app.google/LKY4VL67TaSfDuLGA">
                            <button>I love hardware!</button>
                        </a>
                        <a href="https://dontbuildsaas.com/">
                            <button title="external link">I love SaaS/AI!</button>
                        </a>
                    </p>
                </>
            ),
        },
        {
            title: "🥂 Gamechangers",
            content: (
                <p className="pillar-content">
                    Are you as excited about the next wave of sustainability and impactful hardware startups as{" "}
                    <a href="/about">we are</a>?
                    <br />
                    <br />
                    Through <a href="./events">events</a>, workshops, and public speaking across the UK we hope to
                    inspire the next generation of entrepreneurs to build a World that they're excited by.
                    <br />
                    <br />
                    <a href="https://calendar.app.google/LKY4VL67TaSfDuLGA">
                        <button>Get in touch</button>
                    </a>
                </p>
            ),
        },
    ];

    return (
        <>
            <section>
                <div className="content">
                    <h2 style={{ margin: "50px" }}>
                        How does <span className="hardstuff">Hard Stuff</span> fit in with your World?
                    </h2>
                    <ItsTimeToBuild />
                    <FlexBox defaultValue={0} elements={elements} maxWidth={2} />
                    <br />
                </div>
            </section>
        </>
    );
};
