import "../App.css";

import { WhatIsHardStuffDoing } from "./Home-sections/WhatIsHardStuffDoing";
import BuildTestXRepeat from "../components/BuildTestXRepeat";

// Pictures
import smartshift_prototype from "../assets/images/smartshift_prototype.png";
import events from "../assets/images/events.jpg";
import waterqm_prototype from "../assets/images/waterqm_prototype (3).png";
import FlexBox from "../components/FlexBox";
import what_we_do from "../assets/images/what_we_do.png";

const Home = (props) => (
    <div className="App">
        <section>
            <h1 className="big-h1">
                <span className="hardstuff">Hard Stuff </span>
            </h1>
            <BuildTestXRepeat />
        </section>
        <section>
            <div className="content">
                <h2>
                    <span className="hardstuff">Hard Stuff</span> launches sustainability hardware.
                </h2>
                <FlexBox
                    defaultValue={0}
                    elements={[
                        {
                            title: (
                                <span>
                                    <a href="/startups">Prototyping & testing for</a> climate focussed hardware
                                    ventures.
                                </span>
                            ),
                            img: smartshift_prototype,
                        },
                        {
                            title: (
                                <span>
                                    <a href="/venture-studio">Venture building</a> in under tapped eco-tech verticals.
                                </span>
                            ),
                            img: waterqm_prototype,
                        },
                        {
                            title: (
                                <span>
                                    Inspiring gamechangers via <a href="./events">events</a> and{" "}
                                    <a href="https://github.com/orgs/Hard-Stuff/repositories">build tools</a>.
                                </span>
                            ),
                            img: events,
                        },
                    ]}
                    maxWidth={1.7}
                    style={{ padding: 0 }}
                />
            </div>
        </section>
        <section>
            <div className="content stack_responsive">
                <img
                    src={what_we_do}
                    style={{ maxHeight: "80%", minWidth: "50%", maxWidth: "90%" }}
                    alt="an explanation as to what Hard Stuff does as prototyping partners"
                />
                <div>
                    <span className="mobile-only">
                        <h2>Physical World</h2>
                        <h2>Physical Problems</h2>
                    </span>
                    <h2 className="desktop-only">Physical World, Physical Problems</h2>
                    <p>
                        It should be <strong>easier</strong> to launch startups that are solving the really tough
                        problems, not harder.
                    </p>
                    <p>
                        Yet 5x more investment goes in to software than hardware (let alone eco and enviro-tech), and is
                        it just us or is all this AI and SaaS just getting dull?
                    </p>
                    <p>
                        So <span className="hardstuff-no-colour">Hard Stuff</span> is here to do more with less, and
                        prove that <strong>now is the time to build impactful hardware</strong>.
                    </p>
                </div>
            </div>
        </section>
        <WhatIsHardStuffDoing />
    </div>
);

export default Home;
