const Collaborations = (props) => {
    const elements = [
        {
            title: "the Impact Accelerator by ASquared",
            content: (
                <p className="pillar-content">
                    ASquared's six-month remote-first Impact Accelerator is back for round two, supporting 20+ pre-seed
                    UK startups with genuine positive impact goals aligned with the UN SDGs.
                    <br />
                    <br />
                    The program offers bespoke mentorship, networking, and a unique chance to pitch for investment from
                    ASquared at the Funding Show. Mentors include industry veterans like Dave Allison, VP Engineering at
                    OVO, and Kristina Pereckaite, Managing Director of South East Angels.
                    <br />
                    <br />
                    If you're already in the Impact Accelerator cohort then you benefit from a unique discount with Hard
                    Stuff saving up to 20% on the milestone rates for building hardware prototypes and conducting
                    effective testing.
                    <br />
                    And if you've worked with Hard Stuff already to build out your first demonstrable prototype then get
                    a £1,000 discount on the Impact Accelerator's regular membership fees if accepted into their Cohort
                    2.
                    <br />
                    <br />
                    <a href="https://ia.asquared.uk/" style={{ fontFamily: "inherit" }}>
                        <button>Find out more about the Impact Accelerator</button>
                    </a>
                </p>
            ),
            logo: "https://assets-global.website-files.com/653259e749830c70453b3221/65538b711d8c2c8b076473a6_Master%20IA%20logo%20purp%20blue-close-p-500.png",
        },
        {
            title: "hardware.london",
            content: (
                <p className="pillar-content">
                    <a href="https://hardware.london/">hardware.hondon</a> is a community of hardware innovators, small
                    businesses, and advocaters for making things that solve real problems. We're based in London where
                    we run events, and team up with sponsors and speakers to shake up and champion the industry!
                    <br />
                    <br />
                    <a href="https://hardware.london/" style={{ fontFamily: "inherit" }}>
                        <button>Find out more about hardware.london</button>
                    </a>
                </p>
            ),
        },
        {
            title: "4WARD Climate DOERs Community",
            content: (
                <p className="pillar-content">
                    Hard Stuff is partnering with 4WARD, the world's largest local-to-global climate community to get
                    you 3 months free access to their global climate collaboration & networking platform to advance your
                    startup & climate goals with: Regular meetups & hubs in 47 cities globally 6 Monthly sector calls &
                    VC pitching Mentor office hours & free help $227k in free resources & perks for startups, VCs,
                    corporates, job seekers...
                    <br />
                    <br />
                    <a href="https://4ward.vc/freeaccess-community07b4" style={{ fontFamily: "inherit" }}>
                        <button>Get 3 months Free Access now</button>
                    </a>
                </p>
            ),
            logo: "https://cdn.prod.website-files.com/65faf93017da2732e18491db/66423013325bac7a04a4f184_4ward%20logo.png",
        },
    ];

    const scrollToSection = (index) => {
        const element = document.getElementById(`section-${index}`);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <h1>Collaborations</h1>
            <section>
                <div className="content">
                    <p>
                        We've found that true innovation and impact comes from multiple great teams striving for a
                        common goal. And so we've partnered up with the fantastic businesses below to provide joint
                        commitments to give impactful start-ups the best chances to <br />
                        <span className="hardstuff-no-colour">change the World</span>.
                    </p>
                    <div style={{ display: "inline", fontSize: ".7em" }}>
                        <span>
                            <em>scroll to</em>
                        </span>
                        {elements.map((elem, idx) => (
                            <button
                                style={{ border: "none", borderRadius: "20px", padding: "5px 15px" }}
                                key={idx}
                                onClick={() => scrollToSection(idx)}
                            >
                                {elem.title}
                            </button>
                        ))}
                    </div>
                </div>
            </section>
            {elements.map((elem, idx) => (
                <section id={`section-${idx}`} key={idx} className={`${idx % 2 ? "" : "flip-colours"}`}>
                    <div className="content">
                        <span>
                            {elem.logo ? (
                                <img src={elem.logo} style={{ height: "1.5em" }} alt={`logo of ${elem.title}`} />
                            ) : (
                                ""
                            )}
                            <h2>{elem.title}</h2>
                        </span>
                        {elem.content}
                    </div>
                </section>
            ))}
        </>
    );
};

export default Collaborations;
