// import { useEffect, useState } from "react";

// Memoized LazyLoadImage Component
const Events = () => {
    // const [listOfImages, setListOfImages] = useState([]);

    // const importAll = (r) => {
    //     return r.keys().map(r);
    // };

    // useEffect(() => {
    //     const images = importAll(require.context("../assets/event/07-aug", false, /\.(png|jpe?g|svg)$/));
    //     setListOfImages(images);
    //     console.log(images);
    // }, []);

    return (
        <>
            <h1>Events</h1>
            <div className="content">
                <p>
                    There's a whole bunch of game-changers out there that are building the hardware that'll solve some
                    really tough problems, so we bring them all centre-stage at Planet-friendly Hardware roughly every 3
                    months, at{" "}
                    <a href="https://www.eventbrite.com/cc/planet-friendly-hardware-3602069">
                        Planet-friendly Hardware
                    </a>
                    .
                </p>
                {/* <Layout
                        colCount={4}
                        minWidth={"60"}
                        items={listOfImages.map((image, index) => (
                            <LazyLoadImage key={index} src={image} alt={`Event Image ${index}`} />
                        ))}
                        gap={15}
                    /> */}
                <div style={{ position: "relative", width: "100%", height: 0, paddingBottom: "56.27198%" }}>
                    <iframe
                        width="1280"
                        height="720"
                        src="https://www.youtube.com/embed/QutNCVF0N3A?&theme=dark&autohide=2"
                        frameborder="0"
                        title="Planet-friendly Hardware networking promo, from Hard Stuff"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen="true"
                    />
                </div>
            </div>
            <div className="content">
                Want more? Sign up to <a href="https://eepurl.com/iVq7tk">our newsletter</a>, or express an interest in
                sponsoring via <a href="mailto:events@hard-stuff.com">events@hard-stuff.com</a>
            </div>
        </>
    );
};

export default Events;
