import { Link, useLocation } from "react-router-dom";
import "./NavBarHeaderFooter.css";
import { useState } from "react";
import menu_icon from "../assets/icons/menu.svg";

function NavBar(props) {
	const { pages } = props;
	const location = useLocation(); // Using useLocation hook
	const [showNavBar, setShowNavBar] = useState(false);

	return (
		<nav>
			<div className="padded-header" />
			<header className="lock-header">
				<ul className={`nav-bar ${showNavBar ? "" : "nav-bar-closed"}`}>
					<br />
					{pages.map(
						(each) =>
							!each?.hidden_from_nav && (
								<Link
									to={each.endpoint}
									key={each.endpoint}
									className="header-link"
									style={
										location.pathname === each.endpoint
											? {
													borderTop: "white solid 1px",
													borderBottom: "white solid 1px",
											  }
											: {}
									}
									onClick={() => {
										setShowNavBar(false);
									}}
								>
									{each.title}
								</Link>
							)
					)}
				</ul>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
					}}
				>
					<a href="/">
						<img
							src={process.env.PUBLIC_URL + "/logo.svg"}
							alt="Let's build the Hard Stuff!"
							height="60px"
							title="Let's build the Hard Stuff!"
							style={{ margin: "20px" }}
						/>
					</a>
					<img
						className="menu-icon"
						alt="menu icon"
						onClick={() => {
							setShowNavBar(!showNavBar);
						}}
						style={showNavBar ? { transform: "rotate(90deg)" } : {}}
						src={menu_icon}
					/>
				</div>
			</header>
		</nav>
	);
}
export default NavBar;
